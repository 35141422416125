<template>

    
    <Teleport to="#app" v-if="props.active">
      <UiModal
        :width="'350px'"
        title="Notification users"
        :is-auto-height="true"
        :is-rounded="true"
        :is-mobile-margin="true"
        :close-button-name="'Close'"
        @close="emits('close')"
        >

        
        <div class="more_users_list">

          <NuxtLink 
            class="more_users" 
              :to="opt.link" 
              v-for="(opt,ind) in listUsers" 
              :key="ind"
              >
            <div class="avatar">
                <img :alt="opt.name" :src="opt.avatar_small">
                <div class="name">{{ opt.name }}</div>
            </div>
          </NuxtLink>

          <div 
            v-if="listUsers.length > 0 && !is_end" 
            @click="loadMoreUsers"
            class=" more_users_btn"
            >
            Load more
          </div>

        </div>

      </UiModal>
    </Teleport>

</template>




<script setup>
 

const emits = defineEmits(['close'])

const { $api } = useNuxtApp()


const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  active: {
    type: Boolean,
    required: true
  },
  notificationId: {
    type: Number,
    required: true
  },
  existsMore: {
    type: Boolean,
    required: true
  }
});


const is_loading = ref(false)
const is_end = ref(!props.existsMore)
const listUsers = ref(props.list)

const loadMoreUsers = () => {

  is_end.value = true
  is_loading.value = true
  new Promise(async (resolve, reject) => {
    
    var data = await $api.getNotificationsUsers(useAuth().getId(), props.notificationId, listUsers.value.length, 100);


    resolve(data)

  }).then((data) => {
    is_loading.value = false
    if(data.items_user)
    listUsers.value = listUsers.value.concat(data.items_user)
  }).catch((err) => {
    is_loading.value = false
    console.log(err)
  })

}

onUnmounted(() => {
  listUsers.value = []
  is_end.value = false
  is_loading.value = false
})


</script>



<style scoped>
 
   

/* MORE USERS */
.more_users_word{
  font-weight: bold;
  cursor: pointer;
}

.more_users_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
    padding: 1rem;
    max-height: 500px;
    overflow-y: auto;
}
.more_users {
    display: inline;
    width: 70px;
    min-height: 70px;
    text-align: center;
    overflow: hidden;
}
.more_users .icon.users{
    font-size: 2rem;
    color: gray;
    margin-top: 1.7rem;
}
.more_users .name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden; /* ???????? ???, ??? ?? ?????????? ? ??????? *//* background: #fc0; */
    /* ???? ???? *//* padding: 5px; */
    /* ???? ?????? ?????? */
    text-overflow: ellipsis; /* ????????? ?????????? */
}

.more_users .avatar {
    width: 80%;
    margin: 0px;
    display: inline-block;
}

.more_users .avatar img {
    width: 100%;
    border-radius: 100px;
}

.more_users_btn{
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}
 
</style>
